import { ComponentProps } from 'react'

export function SwapV2(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 25 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22168 5C5.66939 5 5.22168 5.44772 5.22168 6C5.22168 6.55228 5.66939 7 6.22168 7C6.77396 7 7.22168 6.55228 7.22168 6C7.22168 5.44772 6.77396 5 6.22168 5ZM3.22168 6C3.22168 4.34315 4.56483 3 6.22168 3C7.5279 3 8.63914 3.83481 9.05097 5H16.2217C18.4308 5 20.2217 6.79086 20.2217 9C20.2217 11.2091 18.4308 13 16.2217 13H8.22168C7.11711 13 6.22168 13.8954 6.22168 15C6.22168 16.1046 7.11711 17 8.22168 17H15.3924C15.8042 15.8348 16.9155 15 18.2217 15C19.8785 15 21.2217 16.3431 21.2217 18C21.2217 19.6569 19.8785 21 18.2217 21C16.9155 21 15.8042 20.1652 15.3924 19H8.22168C6.01254 19 4.22168 17.2091 4.22168 15C4.22168 12.7909 6.01254 11 8.22168 11H16.2217C17.3262 11 18.2217 10.1046 18.2217 9C18.2217 7.89543 17.3262 7 16.2217 7H9.05097C8.63914 8.16519 7.5279 9 6.22168 9C4.56483 9 3.22168 7.65685 3.22168 6ZM18.2217 17C17.6694 17 17.2217 17.4477 17.2217 18C17.2217 18.5523 17.6694 19 18.2217 19C18.774 19 19.2217 18.5523 19.2217 18C19.2217 17.4477 18.774 17 18.2217 17Z"
        fill={props.fill || '#9B9B9B'}
      />
    </svg>
  )
}
